import { Storage } from '@ionic/storage';
import { useQuery } from "react-query";
import axiosJWT from "./axiosjwt";
import { triggerSignOut } from './functions';
const DBStore = new Storage()

export const useMyUser = () => {
  return useQuery(
    "myUser",
    async () => {
      await DBStore.create()
      var server = "";
      if (process.env.NODE_ENV === "development") {
        server = "http://localhost:1337";
      } else {
        server = "https://server-bern.jobfairy.ch";
      }
      const res = await axiosJWT.get(server + "/api/a/user/my");

      return res.data;
    },
    {
      onError: () => triggerSignOut(),
      onSuccess: (data: any) => data,
      refetchInterval: 60000,
    }
  );
};

export const useMyProfile = () => {
  return useQuery(
    "myProfile",
    async () => {
      var server = "";
      if (process.env.NODE_ENV === "development") {
        server = "http://localhost:1337";
      } else {
        server = "https://server-bern.jobfairy.ch";
      }
      const res = await axiosJWT.get(server + "/api/a/profile/my");

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};
