import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { returnMobilephone } from "../../components/functions";
import { useMyProfile, useMyUser } from "../../components/queries";
import { T } from "../../components/T";

const EmployerAccountInformation: React.FC<{ props: any }> = ({ props }) => {
  const profile = useMyProfile();
  const user = useMyUser();

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="transparent">
          <IonButtons slot="start">
            <IonBackButton text="Zurück" />
          </IonButtons>

          <IonTitle>
            <T>Kontoinformationen</T>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader className="ion-no-border" collapse="condense">
          <IonToolbar className="transparent">
            <IonTitle size="large">
              <T>Kontoinformationen</T>
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <IonList>
                {user.data?.role?.name === "publicEmployer" && (
                  <IonItem>
                    <IonLabel color="medium">
                      <T>Beschreibung</T>
                    </IonLabel>
                    {profile.data?.description}
                  </IonItem>
                )}

                {user.data?.role?.name === "privateEmployer" && (
                  <IonItem>
                    <IonLabel color="medium">
                      <T>Name</T>
                    </IonLabel>
                    {profile.data?.firstName} {profile.data?.lastName}
                  </IonItem>
                )}

                <IonItem>
                  <IonLabel color="medium">
                    <T>Adresse</T>
                  </IonLabel>
                  {profile.data?.primaryContact?.route}{" "}
                  {profile.data?.primaryContact?.street_number}
                  {", "}
                  {profile.data?.primaryContact?.postal_code}{" "}
                  {profile.data?.primaryContact?.locality}
                </IonItem>

                <IonItem>
                  <IonLabel color="medium">
                    <T>E-Mail</T>
                  </IonLabel>
                  {profile.data?.primaryContact?.email}
                </IonItem>

                {profile.data?.primaryContact.landline && (
                  <IonItem>
                    <IonLabel color="medium">
                      <T>Festnetz</T>
                    </IonLabel>
                    {returnMobilephone(profile.data?.primaryContact?.landline)}
                  </IonItem>
                )}

                <IonItem>
                  <IonLabel color="medium">
                    <T>Mobilnummer</T>
                  </IonLabel>
                  {returnMobilephone(profile.data?.primaryContact?.mobilephone)}
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EmployerAccountInformation;
