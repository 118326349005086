import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { Storage } from "@ionic/storage";
import { format, intervalToDuration } from "date-fns";
import { de } from "date-fns/locale";
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import create from "zustand";
import { useCreateWorkLogByEmployee } from "../../components/employee/mutations";
import { useJobsByEmployee } from "../../components/employee/queries";
import { useStore } from "../../components/store";
import { T } from "../../components/T";
const { setState } = useStore;
const store = create(useStore);
const DBstore = new Storage();

const EmployeeWorkLogAssistent: React.FC = () => {
  const createWorkLog = useCreateWorkLogByEmployee();
  const [details, setDetails] = useState<any>({
    jobId: "",
    employerId: "",
    employeeId: "",
    date: new Date(),
    startTime: format(new Date(), "yyy-MM-dd") + "T06:00:00.000+01:00",
    endTime: format(new Date(), "yyy-MM-dd") + "T06:15:00.000+01:00",
    minutes: 15,
    jobTitle: "",
  });
  const jobs = useJobsByEmployee();
  const [presentAlert] = useIonAlert();
  const showWorkLogAssistent = store((state) => state.showWorkLogAssistent);
  const [swiperRef, setSwiperRef] = useState<any>();
  const [changedStartTime, setChangesStartTime] = useState(false);
  const [changedEndTime, setChangesEndTime] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const createDBStore = async () => {
    await DBstore.create();
    setDetails({
      ...details,
      employeeId: parseInt(await DBstore.get("profileId")),
    });
  };

  useEffect(() => {
    createDBStore();
    // eslint-disable-next-line
  }, []);

  return (
    <IonModal isOpen={showWorkLogAssistent}>
      <IonHeader className="ion-no-border">
        <IonToolbar className="transparent">
          {swiperRef && swiperRef.activeIndex !== 0 && (
            <IonButtons slot="start">
              <IonButton onClick={() => swiperRef.slidePrev()}>
                Zurück
              </IonButton>
            </IonButtons>
          )}

          <IonTitle>Assistent</IonTitle>

          <IonButtons slot="end">
            <IonButton
              onClick={() => setState({ showWorkLogAssistent: false })}
            >
              Abbrechen
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <Swiper onSwiper={setSwiperRef}>
          <SwiperSlide>
            <IonGrid style={{ minHeight: "100vh" }}>
              <IonRow>
                <IonCol className="ion-padding" size="12">
                  <IonText>
                    Für welchen Job möchtest du einen Arbeitseinsatz erstellen?
                  </IonText>
                </IonCol>

                <IonCol size="12">
                  <IonList>
                    {jobs.data?.map((job: any, index: number) => {
                      if (job.isArchived) return null;
                      return (
                        <IonItem
                          color={index % 2 === 0 ? "light" : "default"}
                          onClick={() => {
                            if (job.isArchived) {
                              return presentAlert(
                                "Dieser Job ist nicht mehr aktiv"
                              );
                            }
                            setDetails({
                              ...details,
                              jobId: job.id,
                              employerId: job.employer.id,
                              jobTitle: job.title,
                              employerDetails: job.employer.description
                                ? job.employer.description
                                : job.employer.firstName +
                                  " " +
                                  job.employer.lastName,
                            });
                            swiperRef.slideNext();
                          }}
                          key={job.id}
                          lines="full"
                          style={{ marginBottom: 20 }}
                        >
                          {job.title}
                        </IonItem>
                      );
                    })}
                  </IonList>
                </IonCol>
              </IonRow>
            </IonGrid>
          </SwiperSlide>

          <SwiperSlide>
            <IonGrid style={{ height: "100vh" }}>
              <IonRow>
                <IonCol className="ion-padding" size="12">
                  <IonText>
                    An welchem Tag fand der Arbeitseinsatz statt?
                  </IonText>
                </IonCol>

                <IonCol size="12">
                  <IonDatetime
                    locale="de"
                    firstDayOfWeek={1}
                    onIonChange={(e: any) => {
                      if (details.date !== e.detail.value) {
                        setDetails({ ...details, date: e.detail.value });
                      }
                    }}
                    presentation="date"
                  />
                </IonCol>

                <IonCol size="12">
                  <IonButton
                    disabled={!details.date}
                    expand="block"
                    onClick={() => swiperRef.slideNext()}
                  >
                    Weiter
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </SwiperSlide>

          <SwiperSlide>
            <IonGrid style={{ height: "100vh" }}>
              <IonRow>
                <IonCol className="ion-padding" size="12">
                  <IonText>
                    <T>Wie lautet die Startzeit des Arbeitseinsatzes?</T>
                  </IonText>
                </IonCol>

                <IonCol size="12">
                  <IonDatetime
                    hourCycle="h23"
                    // hourValues="6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22"
                    minuteValues="0,15,30,45"
                    onIonChange={(e: any) => {
                      setChangesStartTime(true);
                      setDetails({
                        ...details,
                        startTime: e.detail.value,
                        minutes:
                          intervalToDuration({
                            start: new Date(e.detail.value),
                            end: new Date(),
                          }).hours! *
                            60 +
                          intervalToDuration({
                            start: new Date(e.detail.value),
                            end: new Date(),
                          }).minutes!,
                      });
                    }}
                    presentation="time"
                    value={details.startTime}
                  />
                </IonCol>

                <IonCol size="12">
                  <IonButton
                    disabled={!changedStartTime}
                    expand="block"
                    onClick={() => swiperRef.slideNext()}
                  >
                    Weiter
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </SwiperSlide>

          <SwiperSlide>
            <IonGrid style={{ height: "100vh" }}>
              <IonRow>
                <IonCol className="ion-padding" size="12">
                  <IonText>
                    Wie lautet die Endzeit des Arbeitseinsatzes?
                  </IonText>
                </IonCol>

                <IonCol size="12">
                  <IonDatetime
                    hourCycle="h23"
                    // hourValues="6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22"
                    minuteValues="0,15,30,45"
                    onIonChange={(e: any) => {
                      setChangesEndTime(true);
                      setDetails({
                        ...details,
                        endTime: e.detail.value,
                        minutes:
                          intervalToDuration({
                            start: new Date(details.startTime),
                            end: new Date(e.detail.value),
                          }).hours! *
                            60 +
                          intervalToDuration({
                            start: new Date(details.startTime),
                            end: new Date(e.detail.value),
                          }).minutes!,
                      });
                    }}
                    presentation="time"
                    value={details.endTime}
                  />
                </IonCol>

                <IonCol size="12">
                  <IonButton
                    disabled={!changedEndTime}
                    expand="block"
                    onClick={(e: any) => {
                      if (details.minutes === 0) {
                        presentAlert({
                          header: "Achtung",
                          message: "Die Mindestarbeitszeit beträgt 15 Minuten.",
                          onDidDismiss: () => swiperRef.slidePrev(),
                        });
                      }
                      swiperRef.slideNext();
                    }}
                  >
                    Weiter
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </SwiperSlide>

          <SwiperSlide>
            <IonGrid style={{ height: "100vh" }}>
              <IonRow>
                <IonCol className="ion-padding" size="12">
                  <IonText>Zusammenfassung</IonText>
                </IonCol>

                <IonCol size="12">
                  <IonList>
                    <IonItem>
                      <IonLabel color="medium">
                        <T>Job</T>
                      </IonLabel>
                      <IonText>{details.jobTitle}</IonText>
                    </IonItem>

                    <IonItem>
                      <IonLabel color="medium">
                        <T>Auftraggeber*in</T>
                      </IonLabel>
                      <IonText>{details.employerDetails}</IonText>
                    </IonItem>

                    <IonItem>
                      <IonLabel color="medium">
                        <T>Arbeitseinsatz vom</T>
                      </IonLabel>
                      <IonText>
                        {details.date &&
                          format(new Date(details.date), "dd. MMMM yyy", {
                            locale: de,
                          })}
                      </IonText>
                    </IonItem>

                    <IonItem>
                      <IonLabel color="medium">
                        <T>Zeitfenster</T>
                      </IonLabel>
                      <IonText>
                        {details.startTime &&
                          format(new Date(details.startTime), "HH:mm")}{" "}
                        -{" "}
                        {details.endTime &&
                          format(new Date(details.endTime), "HH:mm")}
                      </IonText>
                    </IonItem>

                    <IonItem>
                      <IonLabel color="medium">
                        <T>Gesamt</T>
                      </IonLabel>
                      <IonText>
                        {details.startTime &&
                          details.endTime &&
                          intervalToDuration({
                            start: new Date(details.startTime),
                            end: new Date(details.endTime),
                          }).hours}{" "}
                        Std.{" "}
                        {details.startTime &&
                          details.endTime &&
                          intervalToDuration({
                            start: new Date(details.startTime),
                            end: new Date(details.endTime),
                          }).minutes}{" "}
                        Min.
                      </IonText>
                    </IonItem>

                    <IonItem>
                      <IonLabel color="medium">
                        <T>Lohn</T>
                      </IonLabel>
                      <IonText>{(details.minutes / 60) * 13} CHF</IonText>
                    </IonItem>
                  </IonList>
                </IonCol>

                <IonCol size="12">
                  <IonButton
                    expand="block"
                    disabled={isSubmitting}
                    onClick={async (e: any) => {
                      setIsSubmitting(true);
                      await createWorkLog.mutateAsync(details);
                      setIsSubmitting(false);
                      swiperRef.slideNext();
                    }}
                  >
                    Bestätigen
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </SwiperSlide>

          <SwiperSlide>
            <IonGrid style={{ height: "100vh" }}>
              <IonRow>
                <IonCol className="ion-padding" size="12">
                  <IonText>Bestätigung</IonText>
                </IonCol>

                <IonCol className="ion-padding" size="12">
                  <IonText>
                    Der Arbeitseinsatz ist erfolgreich erstellt worden.
                  </IonText>
                </IonCol>

                <IonCol size="12">
                  <IonButton
                    expand="block"
                    onClick={async (e: any) => {
                      setState({ showWorkLogAssistent: false });
                    }}
                  >
                    Abschliessen
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </SwiperSlide>
        </Swiper>
      </IonContent>
    </IonModal>
  );
};

export default EmployeeWorkLogAssistent;
