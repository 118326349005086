import { useMutation, useQueryClient } from "react-query";
import axiosJWT from "../axiosjwt";

export const useCreateWorkLogByEmployee = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: any) => {
      var server = "";
    if (process.env.NODE_ENV === "development") {
      server = "http://localhost:1337";
    } else {
      server = "https://server-bern.jobfairy.ch";
    }
      return await axiosJWT.post(
        server + "/api/a/create-worklog-by-employee",
        { data: payload }
      );
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: async (data: any) => {
        await queryClient.invalidateQueries();
        await queryClient.refetchQueries();
      },
    }
  );
};

export const useUpdateWorkLog = (workLogId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: any) => {
      var server = "";
    if (process.env.NODE_ENV === "development") {
      server = "http://localhost:1337";
    } else {
      server = "https://server-bern.jobfairy.ch";
    }
      return await axiosJWT.post(
        server + "/api/a/update-worklog-by-employee",
        { data: { ...payload, workLogId: workLogId } });
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: async (data: any) => {
        await queryClient.invalidateQueries();
        await queryClient.refetchQueries();
      },
    }
  );
};
