import {
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption
} from "@ionic/react";
import { Storage } from '@ionic/storage';
import { language } from "ionicons/icons";
import React, { useRef } from "react";
import create from "zustand";
import { useStore } from "./store";
const { getState, setState } = useStore;
const t = require("../lang.json");
const DBStore = new Storage()

export const T: React.FC<{ children?: string; text?: any }> = ({
  children,
}) => {
  const store = create(useStore);
  const t = require("../lang.json");
  const l = store((state) => state.language);

  if (!children) return <span></span>;

  if (!t[children]) {
    return <span>Translation missing</span>;
  }

  return <span>{t[children][l]}</span>;
};

export const LanguageSelection: React.FC<{
  dark?: boolean;
  light?: boolean;
  slot?: "start" | "end";
}> = ({ dark, light, slot = "start" }) => {
  const languageCodes = [
    {
      code: "de",
      text: "Deutsch",
    },
    {
      code: "en",
      text: "English",
    },
    {
      code: "fr",
      text: "Français",
    },
    {
      code: "it",
      text: "Italiano",
    },
  ];
  const select = useRef<any>();

  return (
    <React.Fragment>
      <IonButtons slot={slot}>
        <IonButton fill="clear" onClick={() => select.current.click()}>
          <IonIcon icon={language} />
        </IonButton>
      </IonButtons>

      <IonItem style={{ display: "none" }}>
        <IonLabel>
          <T>Sprache wählen</T>
        </IonLabel>
        <IonSelect
          onIonChange={async (e: any) => {
            setState({ language: e.detail.value });
            await DBStore.create()
            await DBStore.set("language", e.detail.value)
            window.location.reload();
          }}
          ref={select}
        >
          {languageCodes.map((language) => {
            return (
              <IonSelectOption key={language.code} value={language.code}>
                {language.text}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      </IonItem>
    </React.Fragment>
  );
};

export const returnTranslation = (text: string) => {
  if (!t[text]) {
    return "TEXT FEHLT";
  }

  return t[text][getState().language];
  // return t[text][await DBStore.get("language")];
};
