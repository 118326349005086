import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
} from "@ionic/react";
import { callOutline, chatbubblesOutline, mailOutline } from "ionicons/icons";

const EmployeeContact: React.FC<{ props: any }> = ({ props }) => {
  const [presentAS] = useIonActionSheet();

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="transparent">
          <IonButtons>
            <IonBackButton text="Zurück" />
          </IonButtons>

          <IonTitle>Kontakt</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader className="ion-no-border" collapse="condense">
          <IonToolbar className="transparent">
            <IonTitle size="large">Kontakt</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <IonList>
                <IonItem
                  onClick={() => {
                    presentAS({
                      buttons: [
                        {
                          handler: () => {
                            window.open("tel:0041319709487");
                          },
                          text: "Standort Köniz",
                        },
                        {
                          handler: () => {
                            window.open("tel:0041313216049");
                          },
                          text: "Standort Bern",
                        },
                        {
                          role: "cancel",
                          text: "Abbrechen",
                        },
                      ],
                    });
                  }}
                >
                  <IonIcon icon={callOutline} slot="start" />
                  Telefon
                </IonItem>

                <IonItem disabled>
                  <IonIcon icon={chatbubblesOutline} slot="start" />
                  Chat
                </IonItem>

                <IonItem
                  onClick={() => {
                    presentAS({
                      buttons: [
                        {
                          handler: () => {
                            window.open("mailto:norman.gattermann@koeniz.ch");
                          },
                          text: "Standort Köniz",
                        },
                        {
                          handler: () => {
                            window.open(
                              "mailto:kontakt@jugend-job-boerse-bern.ch"
                            );
                          },
                          text: "Standort Bern",
                        },
                        {
                          role: "cancel",
                          text: "Abbrechen",
                        },
                      ],
                    });
                  }}
                >
                  <IonIcon icon={mailOutline} slot="start" />
                  E-Mail
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EmployeeContact;
