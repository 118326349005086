import { Storage } from '@ionic/storage';
const DBStore = new Storage()

export const returnMobilephone = (mobilephone: string) => {
  if (!mobilephone) return "";

  return (
    mobilephone.slice(0, 3) +
    " " +
    mobilephone.slice(3, 6) +
    " " +
    mobilephone.slice(6, 8) +
    " " +
    mobilephone.slice(8, 10)
  );
};

export const returnSanitized = (value: string) => {
  while (value.indexOf(" ") !== -1) {
    value = value.replace(" ", "");
  }
  return value;
};

export const triggerSignOut = async () => {
  await DBStore.create()
  await DBStore.clear()
  localStorage.clear()
  window.location.reload();
};
