import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle, IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useWorkLogsByEmployee } from "../../components/employee/queries";
import { useMyProfile } from "../../components/queries";
import { useStore } from "../../components/store";
import { LanguageSelection, T } from "../../components/T";
import EmployeeWorkLogAssistent from "./EmployeeWorkLogAssistent";
const { setState } = useStore;

const EmployeeAssistent: React.FC<{ props: any }> = ({ props }) => {
  const workLogs = useWorkLogsByEmployee();
  const [unconfirmed, setUnconfirmed] = useState(0);
  const myProfile = useMyProfile()
  const myWorkLogs = useWorkLogsByEmployee()
  const [earned, setEarned] = useState({
    paid: 0,
    unpaid: 0
  })

  useEffect(() => {
    if (myWorkLogs.isSuccess) {
      let earnings = { paid: 0, unpaid: 0 }
      for (let i = 0; i < myWorkLogs.data.length; i++) {
        const workLog = myWorkLogs.data[i];
        if (workLog.isPaid) earnings.paid += workLog.minutes / 60 * 13
        if (!workLog.isPaid) earnings.unpaid += workLog.minutes / 60 * 13
      }
      setEarned(earnings)
    }
  }, [myWorkLogs.data, myWorkLogs.isSuccess])

  useEffect(() => {
    let unconfirmedWorkLogs = 0;
    workLogs.data?.forEach((workLog: any) => {
      if (!workLog.confirmedByEmployee) unconfirmedWorkLogs += 1;
    });
    setUnconfirmed(unconfirmedWorkLogs);
    // eslint-disable-next-line
  }, [workLogs.isSuccess, workLogs.isRefetching]);

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="transparent">
          <LanguageSelection />

          <IonTitle>
            <T>Assistent</T>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <EmployeeWorkLogAssistent />

        <IonHeader className="ion-no-border" collapse="condense">
          <IonToolbar className="transparent">
            <IonTitle size="large">
              <T>Assistent</T>
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid style={{ height: "75%" }}>
          <IonRow
            className="ion-align-items-center ion-justify-content-center"
            style={{ height: "100%" }}
          >
            <IonCol className="ion-text-center" size="12">
              <IonText style={{ display: "block", marginBottom: 5, fontSize: 20, fontWeight: 700 }}>
                <T>Willkommen zurück,</T> {myProfile.data?.firstName} 😃
              </IonText>

              <IonCard>
                <IonCardContent>
                  <IonImg src="money.gif" style={{ height: 75 }} />
                  <IonCardHeader>bereits ausbezahlt</IonCardHeader>
                  <IonCardSubtitle>{earned.paid}.-</IonCardSubtitle>
                  <IonCardHeader>auszahlbar</IonCardHeader>
                  <IonCardSubtitle>{earned.unpaid}.-</IonCardSubtitle>
                </IonCardContent>
              </IonCard>

              <IonText style={{ display: "block", marginBottom: 5 }}>
                <T>Was möchtest du machen?</T>
              </IonText>

              <br />

              <IonButton
                expand="block"
                fill="outline"
                onClick={() => setState({ showWorkLogAssistent: true })}
                style={{ marginBottom: 10 }}
              >
                <T>Neuen Arbeitseinsatz erstellen</T>
              </IonButton>

              <IonButton
                disabled={unconfirmed < 1}
                expand="block"
                fill="outline"
                routerLink="/app/employee/worklogs"
                style={{ marginBottom: 10 }}
              >
                <T>Einsatz bestätigen</T>({unconfirmed})
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EmployeeAssistent;
