import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from "@ionic/react";
import { Storage } from "@ionic/storage";
import { colorWandOutline, personOutline } from "ionicons/icons";
import { useEffect } from "react";
import { Route } from "react-router-dom";
import CompleteRegistration from "../pages/employee/CompleteRegistration";
import EmployeeAccount from "../pages/employee/EmployeeAccount";
import EmployeeAccountInformation from "../pages/employee/EmployeeAccountInformation";
import EmployeeAssistent from "../pages/employee/EmployeeAssistent";
import EmployeeContact from "../pages/employee/EmployeeContact";
import EmployeeJobs from "../pages/employee/EmployeeJobs";
import EmployeeSingleJob from "../pages/employee/EmployeeSingleJob";
import EmployeeSingleWorkLog from "../pages/employee/EmployeeSingleWorkLog";
import EmployeeWorkLogs from "../pages/employee/EmployeeWorkLogs";
import EmployerAccount from "../pages/employer/EmployerAccount";
import EmployerAccountInformation from "../pages/employer/EmployerAccountInformation";
import EmployerAssistent from "../pages/employer/EmployerAssistent";
import EmployerContact from "../pages/employer/EmployerContact";
import EmployerSingleInvoice from "../pages/employer/EmployerInvoice";
import EmployerInvoices from "../pages/employer/EmployerInvoices";
import EmployerJobs from "../pages/employer/EmployerJobs";
import EmployerSingleJob from "../pages/employer/EmployerSingleJob";
import EmployerSingleWorkLog from "../pages/employer/EmployerSingleWorkLog";
import EmployerWorkLogs from "../pages/employer/EmployerWorkLogs";
import { useMyUser } from "./queries";
import returnJWT from "./returnFromStorage";
import { useStore } from "./store";
const DBStore = new Storage()

const Tabs: React.FC<{ props: any }> = ({ props }) => {
  const { setState } = useStore;
  const user = useMyUser();

  const createDBStore = async () => {
    await DBStore.create()
    let jwt = localStorage.jwt ? localStorage.jwt : await returnJWT()
    setState({ jwt: jwt });

    if (window.location.pathname === "/app") {
      if (
        user.data?.role?.name === "publicEmployer" ||
        user.data?.role?.name === "privateEmployer"
      ) {
        return props.history.push("/app/employer/assistent");
      } else if (user.data?.role?.name === "employee") {
        if (await DBStore.get("registrationComplete")) {
          return props.history.push("/app/employee/assistent");
        } else {
          return props.history.push("/app/employee/complete-registration");
        }
      }
    }

    if (await DBStore.get("language")) setState({ language: await DBStore.get("language") });
  }

  useEffect(() => {
    createDBStore()

    // eslint-disable-next-line
  }, [user.isSuccess]);

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route
          exact
          path="/app"
          render={() => {
            return <div></div>;
          }}
        />

        {/* EMPLOYER */}

        <Route
          exact
          path="/app/employer/assistent"
          render={(props: any) => {
            return <EmployerAssistent props={props} />;
          }}
        />

        <Route
          exact
          path="/app/employer/account"
          render={(props: any) => {
            return <EmployerAccount props={props} />;
          }}
        />

        <Route
          exact
          path="/app/employer/accountinformation"
          render={(props: any) => {
            return <EmployerAccountInformation props={props} />;
          }}
        />

        <Route
          exact
          path="/app/employer/jobs"
          render={(props: any) => {
            return <EmployerJobs props={props} />;
          }}
        />

        <Route
          exact
          path="/app/employer/jobs/:jobId"
          render={(props: any) => {
            return <EmployerSingleJob props={props} />;
          }}
        />

        <Route
          exact
          path="/app/employer/worklogs"
          render={(props: any) => {
            return <EmployerWorkLogs props={props} />;
          }}
        />

        <Route
          exact
          path="/app/employer/worklogs/:workLogId"
          render={(props: any) => {
            return <EmployerSingleWorkLog props={props} />;
          }}
        />

        <Route
          exact
          path="/app/employer/worklogs/job/:jobId"
          render={(props: any) => {
            return <EmployerWorkLogs props={props} />;
          }}
        />

        <Route
          exact
          path="/app/employer/invoices"
          render={(props: any) => {
            return <EmployerInvoices props={props} />;
          }}
        />

        <Route
          exact
          path="/app/employer/invoices/job/:jobId"
          render={(props: any) => {
            return <EmployerInvoices props={props} />;
          }}
        />

        <Route
          exact
          path="/app/employer/invoices/:invoiceId"
          render={(props: any) => {
            return <EmployerSingleInvoice props={props} />;
          }}
        />

        <Route
          exact
          path="/app/employer/contact"
          render={(props: any) => {
            return <EmployerContact props={props} />;
          }}
        />

        {/* EMPLOYEE */}

        <Route
          exact
          path="/app/employee/complete-registration"
          render={(props: any) => {
            return <CompleteRegistration props={props} />;
          }}
        />

        <Route
          exact
          path="/app/employee/assistent"
          render={(props: any) => {
            return <EmployeeAssistent props={props} />;
          }}
        />

        <Route
          exact
          path="/app/employee/account"
          render={(props: any) => {
            return <EmployeeAccount props={props} />;
          }}
        />

        <Route
          exact
          path="/app/employee/accountinformation"
          render={(props: any) => {
            return <EmployeeAccountInformation props={props} />;
          }}
        />

        <Route
          exact
          path="/app/employee/jobs"
          render={(props: any) => {
            return <EmployeeJobs props={props} />;
          }}
        />

        <Route
          exact
          path="/app/employee/jobs/:jobId"
          render={(props: any) => {
            return <EmployeeSingleJob props={props} />;
          }}
        />

        <Route
          exact
          path="/app/employee/worklogs"
          render={(props: any) => {
            return <EmployeeWorkLogs props={props} />;
          }}
        />

        <Route
          exact
          path="/app/employee/worklogs/:workLogId"
          render={(props: any) => {
            return <EmployeeSingleWorkLog props={props} />;
          }}
        />

        <Route
          exact
          path="/app/employee/worklogs/job/:jobId"
          render={(props: any) => {
            return <EmployeeWorkLogs props={props} />;
          }}
        />

        <Route
          exact
          path="/app/employee/contact"
          render={(props: any) => {
            return <EmployeeContact props={props} />;
          }}
        />
      </IonRouterOutlet>

      <IonTabBar className="tabbar" slot="bottom">
        {/* EMPLOYER¯ */}

        {(user.data?.role?.name === "publicEmployer" ||
          user.data?.role?.name === "privateEmployer") && (
            <IonTabButton href="/app/employer/assistent" tab="assistent">
              <IonIcon icon={colorWandOutline} />
              <IonLabel>Assistent</IonLabel>
            </IonTabButton>
          )}

        {(user.data?.role?.name === "publicEmployer" ||
          user.data?.role?.name === "privateEmployer") && (
            <IonTabButton href="/app/employer/account" tab="account">
              <IonIcon icon={personOutline} />
              <IonLabel>Konto</IonLabel>
            </IonTabButton>
          )}

        {/* EMPLOYEE */}

        {user.data?.role?.name === "employee" && (
          <IonTabButton href="/app/employee/assistent" tab="assistent">
            <IonIcon icon={colorWandOutline} />
            <IonLabel>Assistent</IonLabel>
          </IonTabButton>
        )}

        {user.data?.role?.name === "employee" && (
          <IonTabButton href="/app/employee/account" tab="account">
            <IonIcon icon={personOutline} />
            <IonLabel>Konto</IonLabel>
          </IonTabButton>
        )}
      </IonTabBar>
    </IonTabs>
  );
};

export default Tabs;
