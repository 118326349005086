import { Storage } from '@ionic/storage';
import axios from 'axios';
import { useStore } from "./store";
const DBStore = new Storage()

export const sendToken = async (mobilephone: any) => {
  try {
    var server = "";
    if (process.env.NODE_ENV === "development") {
      server = "http://localhost:1337";
    } else {
      server = "https://server-bern.jobfairy.ch";
    }

    await axios.post(server + "/api/a/send-token", {
      data: { identifier: mobilephone },
    });

    return 200;
  } catch (error: any) {
    return error;
  }
};

export const checkToken = async (mobilephone: any, token: any) => {
  const { setState } = useStore;

  try {
    var server = "";
    if (process.env.NODE_ENV === "development") {
      server = "http://localhost:1337";
    } else {
      server = "https://server-bern.jobfairy.ch";
    }
    const res = await axios.post(server + "/api/a/check-token", {
      data: { identifier: mobilephone, password: token },
    });

    const { jwt, profileId, userId, registrationComplete, refreshToken } = res.data;

    setState({ jwt: jwt });
    await DBStore.create()
    await DBStore.set("jwt", jwt)
    await DBStore.set("profileId", profileId)
    await DBStore.set("refreshToken", refreshToken)
    await DBStore.set("userId", userId)
    await DBStore.set("registrationComplete", registrationComplete)
    localStorage.jwt = jwt
    localStorage.profileId = profileId
    localStorage.refreshToken = refreshToken
    localStorage.userId = userId
    localStorage.registrationComplete = registrationComplete

    return 200;
  } catch (error: any) {
    console.log(error);
    return error;
  }
};