import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { checkbox, checkboxOutline } from "ionicons/icons";
import { useParams } from "react-router";
import { useWorkLogsByEmployee } from "../../components/employee/queries";

const EmployeeWorkLogs: React.FC<{ props: any }> = ({ props }) => {
  const params: any = useParams();
  const workLogs = useWorkLogsByEmployee();

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="transparent">
          <IonButtons>
            <IonBackButton text="Zurück" />
          </IonButtons>

          <IonTitle>Arbeitseinsätze</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader className="ion-no-border" collapse="condense">
          <IonToolbar className="transparent">
            <IonTitle size="large">Arbeitseinsätze</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid style={{ height: "90%" }}>
          <IonRow
            className="ion-justify-content-center"
            style={{ height: "100%" }}
          >
            <IonCol className="ion-text-center" size="12">
              {/* <IonSegment
                onIonChange={(e: any) => setSegment(e.detail.value)}
                value={segment}
              >
                <IonSegmentButton value="unconfirmed">
                  unbestätigt
                </IonSegmentButton>
                <IonSegmentButton value="confirmed">bestätigt</IonSegmentButton>
              </IonSegment> */}

              <IonList>
                {workLogs.data?.map((workLog: any) => {
                  if (
                    params.jobId &&
                    workLog.job?.id.toString() !== params.jobId.toString()
                  )
                    return null;
                  if (workLog.confirmedByEmployee) return null;
                  return (
                    <IonItem
                      routerLink={"/app/employee/worklogs/" + workLog.id}
                      key={workLog.id}
                    >
                      <IonLabel color={workLog.isPaid ? "success" : ""}>
                        {(workLog.minutes / 60) * 13} CHF
                        <br />
                        <i style={{ fontSize: 14 }}>
                          {format(new Date(workLog.date), "dd. MMMM yyy", {
                            locale: de,
                          })}
                          {", "}
                          {workLog.startTime !== "00:00:00.000" && (
                            <span>
                              {workLog.startTime.slice(0, 5)} bis{" "}
                              {workLog.endTime.slice(0, 5)} Uhr
                            </span>
                          )}
                          {workLog.startTime === "00:00:00.000" && (
                            <span>
                              {Math.floor(workLog.minutes / 60)} Std.{" "}
                              {workLog.minutes % 60 > 0 &&
                                (workLog.minutes % 60) + "Min."}
                            </span>
                          )}
                        </i>
                      </IonLabel>
                      <IonIcon color="success" icon={checkboxOutline} />
                    </IonItem>
                  );
                })}
              </IonList>

              {/* {segment === "unconfirmed" && (
              )} */}

              <IonList>
                {workLogs.data?.map((workLog: any) => {
                  if (
                    params.jobId &&
                    workLog.job?.id.toString() !== params.jobId.toString()
                  )
                    return null;
                  if (!workLog.confirmedByEmployee) return null;
                  return (
                    <IonItem
                      routerLink={"/app/employee/worklogs/" + workLog.id}
                      key={workLog.id}
                    >
                      <IonLabel>
                        {(workLog.minutes / 60) * 15} CHF
                        <br />
                        <i style={{ fontSize: 14 }}>
                          {format(new Date(workLog.date), "dd. MMMM yyy", {
                            locale: de,
                          })}
                          {", "}
                          {workLog.startTime !== "00:00:00.000" && (
                            <span>
                              {workLog.startTime.slice(0, 5)} bis{" "}
                              {workLog.endTime.slice(0, 5)} Uhr
                            </span>
                          )}
                          {workLog.startTime === "00:00:00.000" && (
                            <span>
                              {Math.floor(workLog.minutes / 60)} Std.{" "}
                              {workLog.minutes % 60 > 0 &&
                                (workLog.minutes % 60) + "Min."}
                            </span>
                          )}
                        </i>
                      </IonLabel>
                      <IonIcon color="success" icon={checkbox} />
                    </IonItem>
                  );
                })}
              </IonList>

              {/* {segment === "confirmed" && (
              )} */}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EmployeeWorkLogs;
