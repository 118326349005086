import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { returnMobilephone } from "../../components/functions";
import { useMyProfile } from "../../components/queries";

const EmployeeAccountInformation: React.FC<{ props: any }> = ({ props }) => {
  const profile = useMyProfile();

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="transparent">
          <IonButtons slot="start">
            <IonBackButton text="Zurück" />
          </IonButtons>

          <IonTitle>Kontoinformationen</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader className="ion-no-border" collapse="condense">
          <IonToolbar className="transparent">
            <IonTitle size="large">Kontoinformationen</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <IonList>
                <IonItem>
                  <IonLabel color="medium">Name</IonLabel>
                  {profile.data?.firstName} {profile.data?.lastName}
                </IonItem>

                <IonItem>
                  <IonLabel color="medium">Adresse</IonLabel>
                  {profile.data?.primaryContact?.route}{" "}
                  {profile.data?.primaryContact?.street_number}
                  {", "}
                  {profile.data?.primaryContact?.postal_code}{" "}
                  {profile.data?.primaryContact?.locality}
                </IonItem>

                <IonItem>
                  <IonLabel color="medium">E-Mail</IonLabel>
                  {profile.data?.primaryContact?.email}
                </IonItem>

                <IonItem>
                  <IonLabel color="medium">Mobilnummer</IonLabel>
                  {returnMobilephone(profile.data?.primaryContact?.mobilephone)}
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EmployeeAccountInformation;
