import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
} from "@ionic/react";
import { callOutline, chatbubblesOutline, mailOutline } from "ionicons/icons";
import { returnTranslation, T } from "../../components/T";

const EmployerContact: React.FC<{ props: any }> = ({ props }) => {
  const [presentAS] = useIonActionSheet();

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="transparent">
          <IonButtons>
            <IonBackButton text="Zurück" />
          </IonButtons>

          <IonTitle>
            <T>Kontakt</T>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader className="ion-no-border" collapse="condense">
          <IonToolbar className="transparent">
            <IonTitle size="large">
              <T>Kontakt</T>
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <IonList>
                <IonItem
                  onClick={() => {
                    presentAS({
                      buttons: [
                        {
                          handler: () => {
                            window.open("tel:0041319709487");
                          },
                          text: returnTranslation("Standort Köniz"),
                        },
                        {
                          handler: () => {
                            window.open("tel:0041313216049");
                          },
                          text: returnTranslation("Standort Bern"),
                        },
                        {
                          role: "cancel",
                          text: returnTranslation("Abbrechen"),
                        },
                      ],
                    });
                  }}
                >
                  <IonIcon icon={callOutline} slot="start" />
                  <T>Telefon</T>
                </IonItem>

                <IonItem disabled>
                  <IonIcon icon={chatbubblesOutline} slot="start" />
                  <T>Chat</T>
                </IonItem>

                <IonItem
                  onClick={() => {
                    presentAS({
                      buttons: [
                        {
                          handler: () => {
                            window.open("mailto:norman.gattermann@koeniz.ch");
                          },
                          text: returnTranslation("Standort Köniz"),
                        },
                        {
                          handler: () => {
                            window.open(
                              "mailto:kontakt@jugend-job-boerse-bern.ch"
                            );
                          },
                          text: returnTranslation("Standort Bern"),
                        },
                        {
                          role: "cancel",
                          text: returnTranslation("Abbrechen"),
                        },
                      ],
                    });
                  }}
                >
                  <IonIcon icon={mailOutline} slot="start" />
                  <T>E-Mail</T>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EmployerContact;
