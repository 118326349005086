import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { checkbox } from "ionicons/icons";
import React from "react";
import { useParams } from "react-router";
import { useUpdateWorkLog } from "../../components/employer/mutations";
import { useWorkLogsByEmployer } from "../../components/employer/queries";
import { returnTranslation, T } from "../../components/T";

const EmployerSingleWorkLog: React.FC<{ props: any }> = ({ props }) => {
  const params: any = useParams();
  const [presentAlert] = useIonAlert();
  const updateWorkLog = useUpdateWorkLog(params.workLogId);
  const workLogs = useWorkLogsByEmployer();

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="transparent">
          <IonButtons>
            <IonBackButton text="Zurück" />
          </IonButtons>

          <IonTitle>
            <T>Arbeitseinsatz</T>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader className="ion-no-border" collapse="condense">
          <IonToolbar className="transparent">
            <IonTitle size="large">
              <T>Arbeitseinsatz</T>
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid style={{ height: "100%" }}>
          <IonRow
            className="ion-justify-content-center"
            style={{ height: "100%" }}
          >
            <IonCol className="ion-text-center" size="12">
              {workLogs.data?.map((workLog: any) => {
                if (workLog.id.toString() !== params.workLogId.toString())
                  return null;
                return (
                  <React.Fragment key={workLog.id}>
                    <IonList>
                      <IonItem>
                        <IonLabel color="medium">
                          <T>Kosten</T>
                        </IonLabel>
                        <IonText>{(workLog.minutes / 60) * 15} CHF</IonText>
                      </IonItem>

                      <IonItem>
                        <IonLabel color="medium">
                          <T>Datum</T>
                        </IonLabel>
                        <IonText>
                          {format(new Date(workLog.date), "dd. MMMM yyy", {
                            locale: de,
                          })}
                        </IonText>
                      </IonItem>

                      {workLog.startTime !== "00:00:00.000" && (
                        <IonItem>
                          <IonLabel color="medium">
                            <T>Zeitfenster</T>
                          </IonLabel>
                          <IonText>
                            {workLog.startTime.slice(0, 5)} bis{" "}
                            {workLog.endTime.slice(0, 5)} Uhr
                          </IonText>
                        </IonItem>
                      )}

                      <IonItem>
                        <IonLabel color="medium">
                          <T>Dauer</T>
                        </IonLabel>
                        <IonText>
                          {Math.floor(workLog.minutes / 60)} Std.{" "}
                          {workLog.minutes % 60 > 0 &&
                            (workLog.minutes % 60) + "Min."}
                        </IonText>
                      </IonItem>

                      <IonItem>
                        <IonLabel color="medium">
                          <T>Jugendliche*r</T>
                        </IonLabel>
                        <IonText>
                          {workLog.employee?.firstName}{" "}
                          {workLog.employee?.lastName}
                        </IonText>
                      </IonItem>

                      <IonItem
                        onClick={() => {
                          if (workLog.invoice) {
                            props.history.push(
                              "/app/employer/invoices/" + workLog.invoice?.id
                            );
                          }
                        }}
                      >
                        <IonLabel color="medium">
                          <T>Rechnung</T>
                        </IonLabel>
                        {workLog.invoice && (
                          <IonText>
                            {workLog.invoice?.smallinvoice_invoice_data?.item?.number}
                          </IonText>
                        )}
                        {!workLog.invoice && (
                          <IonText>
                            <T>noch nicht in Rechung gestellt</T>
                          </IonText>
                        )}
                      </IonItem>

                      <br />

                      <IonItemDivider color="light">
                        <T>Bestätigungen</T>
                      </IonItemDivider>

                      <IonItem>
                        <IonLabel>
                          <T>Jugend-Job-Börse</T>
                        </IonLabel>
                        {workLog.confirmedByJobfair && (
                          <IonIcon color="success" icon={checkbox} />
                        )}
                      </IonItem>

                      <IonItem>
                        <IonLabel>
                          <T>Auftraggeber*in</T>
                        </IonLabel>
                        {workLog.confirmedByEmployer && (
                          <IonIcon color="success" icon={checkbox} />
                        )}
                      </IonItem>

                      <IonItem>
                        <IonLabel>
                          <T>Jugendliche*r</T>
                        </IonLabel>
                        {workLog.confirmedByEmployee && (
                          <IonIcon color="success" icon={checkbox} />
                        )}
                      </IonItem>
                    </IonList>

                    <br />

                    {!workLog.confirmedByEmployer && (
                      <IonButton
                        disabled={workLog.confirmedByEmployer}
                        expand="block"
                        fill="outline"
                        onClick={() => {
                          presentAlert({
                            buttons: [
                              {
                                role: "cancel",
                                text: returnTranslation("Abbrechen"),
                              },
                              {
                                handler: async () => {
                                  await updateWorkLog.mutateAsync({
                                    confirmedByEmployer: true,
                                  });
                                  props.history.goBack();
                                },
                                text: returnTranslation("Ja"),
                              },
                            ],
                            header: returnTranslation("Sicher?"),
                            message: returnTranslation(
                              "Willst du diesen Arbeitseinsatz wirklich bestätigen?"
                            ),
                          });
                        }}
                      >
                        <T>Bestätigen</T>
                      </IonButton>
                    )}
                  </React.Fragment>
                );
              })}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EmployerSingleWorkLog;
