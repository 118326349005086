import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useWorkLogsByEmployer } from "../../components/employer/queries";
import { useStore } from "../../components/store";
import { LanguageSelection, T } from "../../components/T";
import EmployerWorkLogAssistent from "./EmployerWorkLogAssistent";
const { setState } = useStore;

const EmployerAssistent: React.FC<{ props: any }> = ({ props }) => {
  const workLogs = useWorkLogsByEmployer();
  const [unconfirmed, setUnconfirmed] = useState(0);

  useEffect(() => {
    let unconfirmedWorkLogs = 0;
    workLogs.data?.forEach((workLog: any) => {
      if (!workLog.confirmedByEmployer) unconfirmedWorkLogs += 1;
    });
    setUnconfirmed(unconfirmedWorkLogs);
    // eslint-disable-next-line
  }, [workLogs.isSuccess, workLogs.isRefetching]);

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="transparent">
          <LanguageSelection />

          <IonTitle>
            <T>Assistent</T>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <EmployerWorkLogAssistent />

        <IonHeader className="ion-no-border" collapse="condense">
          <IonToolbar className="transparent">
            <IonTitle size="large">
              <T>Assistent</T>
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid style={{ height: "75%" }}>
          <IonRow
            className="ion-align-items-center ion-justify-content-center"
            style={{ height: "100%" }}
          >
            <IonCol className="ion-text-center" size="10">
              <IonText
                color="medium"
                style={{ display: "block", marginBottom: 5 }}
              >
                jobfairy-<T>Assistent</T>
              </IonText>

              <IonText style={{ display: "block", marginBottom: 5 }}>
                <T>Was möchten Sie machen?</T>
              </IonText>

              <br />

              <IonButton
                expand="block"
                fill="outline"
                onClick={() => setState({ showWorkLogAssistent: true })}
                style={{ marginBottom: 10 }}
              >
                <T>Neuen Arbeitseinsatz erstellen</T>
              </IonButton>

              <IonButton
                disabled={unconfirmed < 1}
                expand="block"
                fill="outline"
                routerLink="/app/employer/worklogs"
                style={{ marginBottom: 10 }}
              >
                <T>Einsatz bestätigen</T>({unconfirmed})
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EmployerAssistent;
