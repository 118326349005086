import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonAlert
} from "@ionic/react";
import { Storage } from '@ionic/storage';
import {
  callOutline,
  documentOutline,
  documentsOutline,
  documentTextOutline,
  logOutOutline,
  personOutline
} from "ionicons/icons";
import { useEffect } from "react";
import { useMyProfile } from "../../components/queries";
import { returnTranslation, T } from "../../components/T";
const DBStore = new Storage()

const EmployerAccount: React.FC<{ props: any }> = ({ props }) => {
  const [presentAlert] = useIonAlert();
  const profile = useMyProfile();

  useEffect(() => {
    if (profile.data?.awaitsConfirmation) {
      props.history.push("/");
    }
    // eslint-disable-next-line
  }, [profile.isSuccess]);

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="transparent">
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                presentAlert({
                  buttons: [
                    {
                      role: "cancel",
                      text: returnTranslation("Abbrechen"),
                    },
                    {
                      handler: async () => {
                        await DBStore.create()
                        await DBStore.clear()
                        localStorage.clear()
                        window.location.reload();
                      },
                      role: "destructive",
                      text: returnTranslation("Abmelden"),
                    },
                  ],
                  header: "Abmelden?",
                  message: returnTranslation(
                    "Willst du dich wirklich abmelden?"
                  ),
                });
              }}
            >
              <IonIcon slot="start" icon={logOutOutline} />
              <T>Abmelden</T>
            </IonButton>
          </IonButtons>

          <IonTitle>
            <T>Konto</T>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader className="ion-no-border" collapse="condense">
          <IonToolbar className="transparent">
            <IonTitle size="large">
              <T>Konto</T>
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <IonList>
                <IonItem routerLink="/app/employer/accountinformation">
                  <IonIcon icon={personOutline} slot="start" />
                  <T>Kontoinformationen</T>
                </IonItem>

                <IonItem routerLink="/app/employer/jobs">
                  <IonIcon icon={documentOutline} slot="start" />
                  <T>Jobs</T>
                </IonItem>

                <IonItem routerLink="/app/employer/worklogs">
                  <IonIcon icon={documentsOutline} slot="start" />
                  <T>Arbeitseinsätze</T>
                </IonItem>

                {/* <IonItem routerLink="/app/employer/comments">
                  <IonIcon icon={chatboxOutline} slot="start" />
                  <T>Kommentare</T>
                </IonItem> */}

                <IonItem routerLink="/app/employer/invoices">
                  <IonIcon icon={documentTextOutline} slot="start" />
                  <T>Rechnungen</T>
                </IonItem>

                <IonItem routerLink="/app/employer/contact">
                  <IonIcon icon={callOutline} slot="start" />
                  <T>Kontakt</T>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EmployerAccount;
