import { Storage } from '@ionic/storage';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import returnJWT from './returnFromStorage';
const axiosJWT = axios.create()
const DBStore = new Storage();

const getRefreshToken = async () => {
    await DBStore.create()
    let rT = await DBStore.get("refreshToken")
    var axios = require('axios');
    var data = JSON.stringify({
        "refreshToken": rT
    });

    var config = {
        method: 'post',
        url: process.env.REACT_APP_SERVER + '/api/token/refresh',
        headers: {
            'Content-Type': 'application/json',
            // 'Cookie': 'refreshToken=' + rT
        },
        data: data
    };

    axios(config)
        .then(function (response: any) {
            localStorage.jwt = response.data.jwt.replace('"', "").replace('"', "")
            return response.data.jwt.replace('"', "").replace('"', "")
        })
        .catch(function (error: any) {
            console.log(error);
        });
};

axiosJWT.interceptors.request.use(
    async (config: any) => {
        await DBStore.create();
        const accessToken = await returnJWT()
        if (accessToken) {
            let currentDate = new Date();
            const decodedToken: any = jwt_decode(accessToken);
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                const data: any = await getRefreshToken();
                if (data) {
                    await DBStore.set("jwt", data)
                    localStorage.jwt = data
                    config.headers["authorization"] = "Bearer " + data;
                } else {
                    // no auth header
                }
            } else {
                config.headers["authorization"] = "Bearer " + accessToken;
            }
        } else {
            const data: any = await getRefreshToken();
            if (data) {
                await DBStore.set("jwt", data)
                localStorage.jwt = data
                config.headers["authorization"] = "Bearer " + data;
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosJWT