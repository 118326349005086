import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { Storage } from '@ionic/storage';
import { useEffect } from "react";
import { useStore } from "../components/store";
import { LanguageSelection, T } from "../components/T";
const { setState } = useStore;
const DBStore = new Storage()

const Home: React.FC<{ props: any }> = ({ props }) => {
  const createDBStore = async () => {
    await DBStore.create()
    if (await DBStore.get("language")) setState({ language: await DBStore.get("language") });
  }
  useEffect(() => {
    createDBStore()
    
    // eslint-disable-next-line
  }, []);

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="transparent">
          <LanguageSelection />

          <IonTitle>jobfairy</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonGrid style={{ height: "90%" }}>
          <IonRow
            className="ion-align-items-end ion-justify-content-center"
            style={{ height: "100%" }}
          >
            <IonCol className="ion-text-center ion-padding" size="12">
              <IonImg
                src="/logo.png"
                style={{ margin: "auto", width: "50vw" }}
              />

              <IonText>
                <T>Willkommen bei der jobfairy-App der</T>
                <br /> <T>Jugend-Job-Börse Bern.</T>
              </IonText>

              <br />
              <br />
              <br />

              <IonButton
                expand="block"
                routerLink="/signin"
                style={{ marginBottom: 10 }}
              >
                <T>Anmelden</T>
              </IonButton>

              <br />
              <br />
              <br />
              <br />
              <br />
              <br />

              <IonText>Version 0.5</IonText>

              {/* <IonButton
                color="light"
                disabled
                expand="block"
                routerLink="/register"
              >
                <T>Registrieren</T>
              </IonButton> */}

              {/* <br /> */}
              <br />
              <br />
              <br />
              <br />
              <br />

              <IonText color="medium" style={{ fontSize: 12 }}>
                <T>Mehr Informationen unter</T>{" "}
                <a href="https://www.jugend-job-boerse-bern.ch">
                  jugend-job-boerse-bern.ch
                </a>
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Home;
