import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { documentsOutline } from "ionicons/icons";
import { useParams } from "react-router";
import { useJobById } from "../../components/employer/queries";
import { T } from "../../components/T";

const EmployerSingleJob: React.FC<{ props: any }> = ({ props }) => {
  const params: any = useParams();
  const job = useJobById(params.jobId);

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="transparent">
          <IonButtons>
            <IonBackButton text="Zurück" />
          </IonButtons>

          <IonTitle>{job.data?.title}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader className="ion-no-border" collapse="condense">
          <IonToolbar className="transparent">
            <IonTitle size="large">{job.data?.title}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid style={{ height: "90%" }}>
          <IonRow>
            <IonCol size="12">
              <IonList>
                <IonItem
                  routerLink={"/app/employer/worklogs/job/" + job.data?.id}
                >
                  <IonIcon icon={documentsOutline} slot="start" />
                  <IonLabel>
                    <T>Arbeitseinsätze</T>
                  </IonLabel>
                </IonItem>

                <IonItem
                  routerLink={"/app/employer/invoices/job/" + job.data?.id}
                >
                  <IonIcon icon={documentsOutline} slot="start" />
                  <IonLabel>
                    <T>Rechnungen</T>
                  </IonLabel>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EmployerSingleJob;
