import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar, useIonLoading,
  useIonToast
} from "@ionic/react";
import { Storage } from '@ionic/storage';
import { useState } from "react";
import { useHistory } from "react-router";
import { returnMobilephone, returnSanitized } from "../components/functions";
import { checkToken, sendToken } from "../components/requests";
import { returnTranslation, T } from "../components/T";
const DBstore = new Storage()

const SignIn: React.FC<{ props: any }> = ({ props }) => {
  const [step, setStep] = useState(1);
  const [mobilephone, setMobilephone] = useState<any>("");
  const [token, setToken] = useState<any>("");
  const [presentToast] = useIonToast();
  const [presentLoading, dismissLoading] = useIonLoading();
  const history = useHistory();

  const check = async () => {
    presentLoading(returnTranslation("Lädt ..."), 1000);

    const res = await checkToken(mobilephone, token);

    if (res !== 200) {
      presentToast({
        color: "danger",
        duration: 1000,
        message: "SMS-Token ungültig",
        position: "top",
      });
    }

    dismissLoading();
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="transparent">
          <IonButtons slot="start">
            <IonBackButton text={returnTranslation("Zurück")} />
          </IonButtons>

          <IonTitle>
            <T>Anmelden</T>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonGrid className="ion-align-items-center" style={{ height: "100%" }}>
          {step === 1 && (
            <IonRow
              className="ion-align-items-center ion-justify-content-center"
              style={{ height: "75%" }}
            >
              <IonCol
                className="ion-text-center"
                sizeXs="10"
                sizeSm="8"
                sizeMd="6"
                sizeLg="4"
              >
                <IonText
                  style={{
                    display: "block",
                    fontSize: 24,
                    fontWeight: 700,
                    margin: 30,
                  }}
                >
                  <T>Mobilnummer eingeben</T>
                </IonText>

                <IonInput
                  autofocus
                  inputMode="numeric"
                  onIonChange={(e: any) =>
                    setMobilephone(returnSanitized(e.currentTarget.value))
                  }
                  onKeyPress={async (e: any) => {
                    if (mobilephone.length !== 10) return undefined;

                    if (e.code === "Enter") {
                      presentLoading(returnTranslation("Lädt ..."));

                      const res = await sendToken(mobilephone);

                      if (res !== 200) {
                        return presentToast({
                          color: "danger",
                          cssClass: "ion-text-center",
                          duration: 1000,
                          message: res.response.data.error.details.message,
                          position: "top",
                        });
                      }

                      dismissLoading();

                      setStep(2);
                    }
                  }}
                  placeholder="z.B. 079 123 45 67"
                  type="tel"
                  value={
                    mobilephone.length !== 10
                      ? mobilephone
                      : returnMobilephone(mobilephone)
                  }
                />

                <IonText style={{ display: "block", marginTop: 10 }}>
                  <T>Wir schicken dir einen SMS-Token</T>
                </IonText>

                <IonButton
                  color="light"
                  disabled={mobilephone.length !== 10}
                  onClick={async () => {
                    presentLoading(returnTranslation("Lädt ..."));

                    const res = await sendToken(mobilephone);

                    if (res !== 200) {
                      return presentToast({
                        color: "danger",
                        cssClass: "ion-text-center",
                        duration: 1000,
                        message: res.response.data.error.details.message,
                        position: "top",
                      });
                    }

                    dismissLoading();

                    setStep(2);
                  }}
                  style={{ marginTop: 30 }}
                >
                  <T>Weiter</T>
                </IonButton>
              </IonCol>
            </IonRow>
          )}

          {step === 2 && (
            <IonRow
              className="ion-align-items-center ion-justify-content-center"
              style={{ height: "75%" }}
            >
              <IonCol
                className="ion-text-center"
                sizeXs="10"
                sizeSm="8"
                sizeMd="6"
                sizeLg="4"
              >
                <IonText
                  style={{
                    display: "block",
                    fontSize: 24,
                    fontWeight: 700,
                    margin: 30,
                  }}
                >
                  <T>Token eingeben</T>
                </IonText>

                <IonInput
                  autofocus
                  inputMode="numeric"
                  onIonChange={(e: any) =>
                    setToken(returnSanitized(e.detail.value))
                  }
                  onKeyPress={async (e: any) => {
                    if (token.length !== 4) return undefined;

                    if (e.code === "Enter") {
                      await check();
                      await DBstore.create()
                      if (!await DBstore.get("registrationComplete")) {
                        history.push("/app/employee/complete-registration");
                      } else {
                        history.push("/app");
                      }
                    }
                  }}
                  placeholder="z.B. 1234"
                  type="tel"
                />

                <IonText
                  onClick={() => setStep(1)}
                  style={{
                    cursor: "pointer",
                    display: "block",
                    marginTop: 10,
                  }}
                >
                  <T>Keinen SMS-Token erhalten?</T>
                </IonText>

                <IonButton
                  color="light"
                  disabled={token.length !== 4}
                  onClick={async () => {
                    await check();
                    await DBstore.create()
                    if (!await DBstore.get("registrationComplete")) {
                      history.push("/app/employee/complete-registration");
                    } else {
                      history.push("/app");
                    }
                  }}
                  style={{ marginTop: 30 }}
                >
                  <T>Weiter</T>
                </IonButton>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SignIn;
