import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { documentsOutline } from "ionicons/icons";
import { useParams } from "react-router";
import { useJobById } from "../../components/employee/queries";
import { returnMobilephone } from "../../components/functions";

const EmployeeSingleJob: React.FC<{ props: any }> = ({ props }) => {
  const params: any = useParams();
  const job = useJobById(params.jobId);

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="transparent">
          <IonButtons>
            <IonBackButton text="Zurück" />
          </IonButtons>

          <IonTitle>{job.data?.title}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader className="ion-no-border" collapse="condense">
          <IonToolbar className="transparent">
            <IonTitle size="large">{job.data?.title}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid style={{ height: "90%" }}>
          <IonRow>
            <IonCol className="ion-padding" size="12">
              <IonCard>
                <IonCardHeader>
                  <IonText><b>Auftraggeber*in</b></IonText>
                </IonCardHeader>
                <IonCardContent>
                  {job.data?.employer.description && (
                    <IonText>{job.data?.employer.description}</IonText>
                  )}
                  {!job.data?.employer.description && (
                    <IonText>{job.data?.employer.firstName} {job.data?.employer.lastName}</IonText>
                  )}
                  <br />
                  <IonText>{job.data?.employer?.contacts[0].route} </IonText>
                  <IonText>{job.data?.employer?.contacts[0].street_number}</IonText>
                  <br />
                  <IonText>{job.data?.employer?.contacts[0].postal_code} </IonText>
                  <IonText>{job.data?.employer?.contacts[0].locality}</IonText>
                  <br />
                  {job.data?.employer?.contacts[0]?.landline && <IonText>
                    <br />
                    {returnMobilephone(job.data?.employer?.contacts[0]?.landline)}
                  </IonText>}
                  {job.data?.employer?.contacts[0]?.mobilephone && <IonText>
                    <br />
                    {returnMobilephone(job.data?.employer?.contacts[0]?.mobilephone)}
                  </IonText>}
                </IonCardContent>
              </IonCard>
            </IonCol>

            <IonCol size="12">
              <IonList>
                <IonItem
                  routerLink={"/app/employee/worklogs/job/" + job.data?.id}
                >
                  <IonIcon icon={documentsOutline} slot="start" />
                  <IonLabel>Arbeitseinsätze</IonLabel>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage >
  );
};

export default EmployeeSingleJob;
