import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useState } from "react";
import { useParams } from "react-router";
import { useInvoicesByEmployer } from "../../components/employer/queries";
import { T } from "../../components/T";

const EmployerInvoices: React.FC<{ props: any }> = ({ props }) => {
  const params: any = useParams();
  const invoices = useInvoicesByEmployer();
  const [segment, setSegment] = useState("unpaid");

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="transparent">
          <IonButtons>
            <IonBackButton text="Zurück" />
          </IonButtons>

          <IonTitle>
            <T>Rechnungen</T>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader className="ion-no-border" collapse="condense">
          <IonToolbar className="transparent">
            <IonTitle size="large">
              <T>Rechnungen</T>
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid style={{ height: "90%" }}>
          <IonRow
            className="ion-justify-content-center"
            style={{ height: "100%" }}
          >
            <IonCol className="ion-text-center" size="12">
              <IonSegment
                onIonChange={(e: any) => setSegment(e.detail.value)}
                value={segment}
              >
                <IonSegmentButton value="unpaid">
                  <T>unbezahlt</T>
                </IonSegmentButton>
                <IonSegmentButton value="paid">
                  <T>bezahlt</T>
                </IonSegmentButton>
              </IonSegment>

              {segment === "unpaid" && (
                <IonList>
                  {invoices?.data?.map((invoice: any) => {
                    if (
                      params.jobId &&
                      invoice.job?.id.toString() !== params.jobId.toString()
                    )
                      return null;
                    if (
                      invoice.smallinvoice_invoice_data?.item?.total ===
                      invoice.smallinvoice_invoice_data?.item?.total_paid
                    )
                      return null;
                    return (
                      <IonItem
                        routerLink={"/app/employer/invoices/" + invoice.id}
                        key={invoice.id}
                      >
                        <IonLabel>
                          {invoice.smallinvoice_invoice_data?.item?.total} CHF
                          <br />
                          <i>{invoice.job?.title}</i>
                        </IonLabel>
                      </IonItem>
                    );
                  })}
                </IonList>
              )}

              {segment === "paid" && (
                <IonList>
                  {invoices?.data?.map((invoice: any) => {
                    if (
                      params.jobId &&
                      invoice.job?.id.toString() !== params.jobId.toString()
                    )
                      return null;
                    if (
                      invoice.smallinvoice_invoice_data?.item?.total >
                      invoice.smallinvoice_invoice_data?.item?.total_paid
                    )
                      return null;
                    return (
                      <IonItem
                        routerLink={"/app/employer/invoices/" + invoice.id}
                        key={invoice.id}
                      >
                        <IonLabel>
                          {invoice.smallinvoice_invoice_data?.item?.total} CHF
                        </IonLabel>
                      </IonItem>
                    );
                  })}
                </IonList>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EmployerInvoices;
