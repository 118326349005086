import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import { Storage } from '@ionic/storage';
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Redirect, Route } from "react-router";
import "./App.css";
import returnJWT from "./components/returnFromStorage";
import Tabs from "./components/Tabs";
import Home from "./pages/Home";
import Signin from "./pages/Signin";
/* Theme variables */
import "./theme/variables.css";
const DBStore = new Storage()

setupIonicReact();

const App: React.FC = () => {
  const queryClient = new QueryClient();
  const [jwt, setJwt] = useState("")

  const createDBStore = async () => {
    await DBStore.create()
    if (!await DBStore.get("language")) await DBStore.set("language", "de");
    setJwt(await returnJWT())
  }

  useEffect(() => {
    createDBStore()

    console.log(process.env.NODE_ENV);
    // eslint-disable-next-line
  }, []);

  return (
    <IonApp>
      <QueryClientProvider client={queryClient}>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route
              exact
              path="/home"
              render={(props: any) => {
                if (!jwt || !localStorage.jwt) {
                  return <Home props={props} />;
                }
                return <Redirect to="/app" />;
              }}
            />

            <Route
              exact
              path="/signin"
              render={(props: any) => {
                if (!jwt || !localStorage.jwt) {
                  return <Signin props={props} />;
                }
                return <Redirect to="/app" />;
              }}
            />

            <Route
              path="/app"
              render={(props: any) => {
                if (jwt || localStorage.jwt) {
                  return <Tabs props={props} />;
                }
                return <Redirect to="/" />;
              }}
            />

            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
          </IonRouterOutlet>
        </IonReactRouter>
      </QueryClientProvider>
    </IonApp>
  );
};

export default App;
