import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert
} from "@ionic/react";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { checkbox } from "ionicons/icons";
import React from "react";
import { useParams } from "react-router";
import { useUpdateWorkLog } from "../../components/employee/mutations";
import { useWorkLogsByEmployee } from "../../components/employee/queries";

const EmployerSingleWorkLog: React.FC<{ props: any }> = ({ props }) => {
  const params: any = useParams();
  const [presentAlert] = useIonAlert();
  const updateWorkLog = useUpdateWorkLog(params.workLogId);
  const workLogs = useWorkLogsByEmployee();

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="transparent">
          <IonButtons>
            <IonBackButton text="Zurück" />
          </IonButtons>

          <IonTitle>Arbeitseinsatz</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader className="ion-no-border" collapse="condense">
          <IonToolbar className="transparent">
            <IonTitle size="large">Arbeitseinsatz</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid style={{ height: "100%" }}>
          <IonRow
            className="ion-justify-content-center"
            style={{ height: "100%" }}
          >
            <IonCol className="ion-text-center" size="12">
              {workLogs.data?.map((workLog: any) => {
                if (workLog.id.toString() !== params.workLogId.toString()) return null
                return (
                  <React.Fragment key={workLog.id}>
                    <IonList>
                      <IonItem>
                        <IonLabel color="medium">Lohn</IonLabel>
                        <IonText>{(workLog.minutes / 60) * 13} CHF</IonText>
                      </IonItem>

                      <IonItem>
                        <IonLabel color="medium">Datum</IonLabel>
                        <IonText>
                          {format(new Date(workLog.date), "dd. MMMM yyy", {
                            locale: de,
                          })}
                        </IonText>
                      </IonItem>

                      {workLog.startTime !== "00:00:00.000" && (
                        <IonItem>
                          <IonLabel color="medium">Zeitfenster</IonLabel>
                          <IonText>
                            {workLog.startTime.slice(0, 5)} bis{" "}
                            {workLog.endTime.slice(0, 5)} Uhr
                          </IonText>
                        </IonItem>
                      )}

                      <IonItem>
                        <IonLabel color="medium">Dauer</IonLabel>
                        <IonText>
                          {Math.floor(workLog.minutes / 60)} Std.{" "}
                          {workLog.minutes % 60 > 0 &&
                            (workLog.minutes % 60) + "Min."}
                        </IonText>
                      </IonItem>

                      <IonItem>
                        <IonLabel color="medium">Auftraggeber*in</IonLabel>
                        {workLog.employer?.description && (
                          <IonText>{workLog.employer?.description} </IonText>
                        )}
                        {workLog.employer?.firstName &&
                          workLog.employer?.lastName && (
                            <IonText>
                              {workLog.employer?.firstName}{" "}
                              {workLog.employer?.lastName}
                            </IonText>
                          )}
                      </IonItem>

                      <IonItem>
                        <IonLabel color="medium">Status</IonLabel>
                        <IonText color={workLog.isPaid ? "success" : ""}>{workLog.isPaid ? "ausbezahlt" : "noch nicht ausbezahlt"}</IonText>
                      </IonItem>

                      <br />

                      <IonItemDivider color="light">
                        Bestätigungen
                      </IonItemDivider>

                      <IonItem>
                        <IonLabel>Jugend-Job-Börse</IonLabel>
                        {workLog.confirmedByJobfair && (
                          <IonIcon color="success" icon={checkbox} />
                        )}
                      </IonItem>

                      <IonItem>
                        <IonLabel>Auftraggeber*in</IonLabel>
                        {workLog.confirmedByEmployer && (
                          <IonIcon color="success" icon={checkbox} />
                        )}
                      </IonItem>

                      <IonItem>
                        <IonLabel>Jugendliche*r</IonLabel>
                        {workLog.confirmedByEmployee && (
                          <IonIcon color="success" icon={checkbox} />
                        )}
                      </IonItem>
                    </IonList>

                    <br />

                    {!workLog.confirmedByEmployee && (
                      <IonButton
                        disabled={workLog.confirmedByEmployee}
                        expand="block"
                        fill="outline"
                        onClick={() => {
                          presentAlert({
                            buttons: [
                              {
                                role: "cancel",
                                text: "Abbrechen",
                              },
                              {
                                handler: async () => {
                                  await updateWorkLog.mutateAsync({
                                    confirmedByEmployee: true,
                                  });
                                  props.history.goBack();
                                },
                                text: "Ja",
                              },
                            ],
                            header: "Sicher?",
                            message:
                              "Willst du diesen Arbeitseinsatz wirklich bestätigen?",
                          });
                        }}
                      >
                        Bestätigen
                      </IonButton>
                    )}
                  </React.Fragment>
                );
              })}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EmployerSingleWorkLog;
