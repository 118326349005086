import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonAlert
} from "@ionic/react";
import { Storage } from '@ionic/storage';
import {
  callOutline,
  documentOutline,
  documentsOutline,
  logOutOutline,
  personOutline
} from "ionicons/icons";
const DBstore = new Storage()

const CompleteRegistration: React.FC<{ props: any }> = ({ props }) => {
  const [presentAlert] = useIonAlert();

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="transparent">
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                presentAlert({
                  buttons: [
                    {
                      role: "cancel",
                      text: "Abbrechen",
                    },
                    {
                      handler: async () => {
                        await DBstore.create()
                        await DBstore.clear()
                        localStorage.clear()
                        window.location.reload();
                      },
                      role: "destructive",
                      text: "Abmelden",
                    },
                  ],
                  header: "Abmelden?",
                  message: "Willst du dich wirklich abmelden?",
                });
              }}
            >
              <IonIcon slot="start" icon={logOutOutline} />
              Abmelden
            </IonButton>
          </IonButtons>

          <IonTitle>Konto</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader className="ion-no-border" collapse="condense">
          <IonToolbar className="transparent">
            <IonTitle size="large">Konto</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <IonList>
                <IonItem routerLink="/app/employee/accountinformation">
                  <IonIcon icon={personOutline} slot="start" />
                  Kontoinformationen
                </IonItem>

                <IonItem routerLink="/app/employee/jobs">
                  <IonIcon icon={documentOutline} slot="start" />
                  Jobs
                </IonItem>

                <IonItem routerLink="/app/employee/worklogs">
                  <IonIcon icon={documentsOutline} slot="start" />
                  Arbeitseinsätze
                </IonItem>

                {/* <IonItem routerLink="/app/employee/comments">
                    <IonIcon icon={chatboxOutline} slot="start" />
                    Kommentare
                  </IonItem> */}

                {/* <IonItem routerLink="/app/employee/invoices">
                    <IonIcon icon={documentTextOutline} slot="start" />
                    Rechnungen
                  </IonItem> */}

                <IonItem routerLink="/app/employee/contact">
                  <IonIcon icon={callOutline} slot="start" />
                  Kontakt
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default CompleteRegistration;
