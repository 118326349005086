import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { useEffect, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useParams } from "react-router";
import axiosJWT from "../../components/axiosjwt";
import { useInvoiceById } from "../../components/employer/queries";
import { T } from "../../components/T";

const EmployerSingleInvoice: React.FC<{ props: any }> = ({ props }) => {
  const params: any = useParams();
  const invoice = useInvoiceById(params.invoiceId);
  const [url, setUrl] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState<any>(1);

  useEffect(() => {
    if (1 + 1 === 3) setPageNumber(numPages);
    // eslint-disable-next-line
  }, []);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const getInvoice = async () => {
    const res = await axiosJWT.get(
      process.env.REACT_APP_SERVER +
      "/api/a/get-invoice-as-pdf/" +
      params.invoiceId);

    setUrl(process.env.REACT_APP_SERVER + "/" + res.data + ".pdf");

    return process.env.REACT_APP_SERVER + "/" + res.data + ".pdf";
  };

  useEffect(() => {
    getInvoice();
    // eslint-disable-next-line
  }, []);

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="transparent">
          <IonButtons>
            <IonBackButton text="Zurück" />
          </IonButtons>

          <IonTitle>
            <T>Rechnung</T>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader className="ion-no-border" collapse="condense">
          <IonToolbar className="transparent">
            <IonTitle size="large">
              <T>Rechnung</T>
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        {url && (
          <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
        )}

        <IonGrid style={{ height: "90%" }}>
          <IonRow
            className="ion-justify-content-center"
            style={{ height: "100%" }}
          >
            <IonCol className="ion-text-center" size="12">
              {invoice.isSuccess && (
                <IonList>
                  <IonItem>
                    <IonLabel color="medium">
                      <T>Rechnungsdatum</T>
                    </IonLabel>
                    <IonText>
                      {format(
                        new Date(invoice.data?.smallinvoice_invoice_data?.item?.date),
                        "dd. MMMM yyy",
                        { locale: de }
                      )}
                    </IonText>
                  </IonItem>

                  <IonItem>
                    <IonLabel color="medium">
                      <T>fällig bis</T>
                    </IonLabel>
                    <IonText>
                      {format(
                        new Date(invoice.data?.smallinvoice_invoice_data?.item?.due),
                        "dd. MMMM yyy",
                        { locale: de }
                      )}
                    </IonText>
                  </IonItem>

                  <IonItem>
                    <IonLabel color="medium">
                      <T>Betrag</T>
                    </IonLabel>
                    <IonText>
                      {invoice.data?.smallinvoice_invoice_data?.item?.total} CHF
                    </IonText>
                  </IonItem>

                  <IonItem>
                    <IonLabel color="medium">
                      <T>bezahlt</T>
                    </IonLabel>
                    <IonText>
                      {invoice.data?.smallinvoice_invoice_data?.item?.total_paid} CHF
                    </IonText>
                  </IonItem>
                </IonList>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EmployerSingleInvoice;
