import { useQuery } from "react-query";
import axiosJWT from "../axiosjwt";

export const useInvoicesByEmployer = () => {
  return useQuery(
    "invoices",
    async () => {
      var server = "";
      if (process.env.NODE_ENV === "development") {
        server = "http://localhost:1337";
      } else {
        server = "https://server-bern.jobfairy.ch";
      }
      const res = await axiosJWT.get(server + "/api/a/get-invoices-by-employer");

      return res.data;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data: any) => data,
    }
  );
};

export const useInvoiceById = (invoiceId: string) => {
  return useQuery(
    ["invoices", { invoiceId: invoiceId }],
    async () => {
      var server = "";
      if (process.env.NODE_ENV === "development") {
        server = "http://localhost:1337";
      } else {
        server = "https://server-bern.jobfairy.ch";
      }
      const res = await axiosJWT.post(
        server + "/api/a/get-invoice-by-id",
        { data: { invoiceId: invoiceId } });

      return res.data;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data: any) => data,
    }
  );
};

export const useCommentsByEmployer = () => {
  return useQuery(
    "comments",
    async () => {
      var server = "";
      if (process.env.NODE_ENV === "development") {
        server = "http://localhost:1337";
      } else {
        server = "https://server-bern.jobfairy.ch";
      }
      const res = await axiosJWT.get(server + "/api/a/get-comments-by-employer");

      return res.data;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data: any) => data,
    }
  );
};

export const useWorkLogsByEmployer = () => {
  return useQuery(
    "worklogs",
    async () => {
      var server = "";
      if (process.env.NODE_ENV === "development") {
        server = "http://localhost:1337";
      } else {
        server = "https://server-bern.jobfairy.ch";
      }
      const res = await axiosJWT.get(server + "/api/a/get-worklogs-by-employer");

      return res.data;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data: any) => data,
    }
  );
};

export const useJobsByEmployer = () => {
  return useQuery(
    "jobs",
    async () => {
      var server = "";
      if (process.env.NODE_ENV === "development") {
        server = "http://localhost:1337";
      } else {
        server = "https://server-bern.jobfairy.ch";
      }
      const res = await axiosJWT.get(server + "/api/a/get-jobs-by-employer");

      return res.data;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data: any) => data,
    }
  );
};

export const useJobById = (jobId: string) => {
  return useQuery(
    ["jobs", { jobId: jobId }],
    async () => {
      var server = "";
      if (process.env.NODE_ENV === "development") {
        server = "http://localhost:1337";
      } else {
        server = "https://server-bern.jobfairy.ch";
      }
      const res: any = await axiosJWT.post(
        server + "/api/a/get-job-by-employer-by-id/",
        { data: { jobId: jobId } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};
