import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useJobsByEmployer } from "../../components/employer/queries";
import { T } from "../../components/T";

const EmployerJobs: React.FC<{ props: any }> = ({ props }) => {
  const jobs = useJobsByEmployer();

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar className="transparent">
          <IonButtons>
            <IonBackButton text="Zurück" />
          </IonButtons>

          <IonTitle>
            <T>Jobs</T>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader className="ion-no-border" collapse="condense">
          <IonToolbar className="transparent">
            <IonTitle size="large">
              <T>Jobs</T>
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid style={{ height: "90%" }}>
          <IonRow
            className="ion-justify-content-center"
            style={{ height: "100%" }}
          >
            <IonCol className="ion-text-center" size="12">
              <IonList>
                {jobs.data?.map((job: any) => {
                  return (
                    <IonItem
                      routerLink={"/app/employer/jobs/" + job.id}
                      key={job.id}
                    >
                      <IonLabel>{job.title}</IonLabel>
                    </IonItem>
                  );
                })}
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EmployerJobs;
